<!-- 
  When adding new controls that are not related to companion mode,
  note that in the PreferenceLayoutComponent.getPanels() method,
  the entire Calling panel is removed when AppFeature.CompanionMode is disabled
-->

<div class="calling-container">
  <div class="header">
    <h1>{{ 'CALLING_PREFERENCES.COMPANION_HEADER' | translate }}</h1>
  </div>
  <div>
    <div class="subheader">{{ 'CALLING_PREFERENCES.COMPANION_SUBHEADER' | translate }}</div>
    <form [formGroup]="companionForm">
      <div class="companion-item">
        <div><mat-slide-toggle formControlName="rememberState"></mat-slide-toggle></div>
        <div>
          <div class="label">{{ 'CALLING_PREFERENCES.COMPANION_REMEMBER_STATE_LABEL' | translate }}</div>
          <div class="hint">
            {{ 'CALLING_PREFERENCES.COMPANION_REMEMBER_STATE_HINT' | translate }}
          </div>
        </div>
      </div>
      <div class="companion-item">
        <mat-form-field
          class="full-width"
          appearance="outline"
          floatLabel="always">
          <mat-label>{{ 'CALLING_PREFERENCES.COMPANION_RINGING_MODE_LABEL' | translate }}</mat-label>
          <mat-select formControlName="ringingMode">
            <mat-option [value]="CompanionRingingMode.CompanionDeviceOnly">
              {{ 'CALLING_PREFERENCES.COMPANION_RING_DEVICE_ONLY' | translate }}
            </mat-option>
            <mat-option [value]="CompanionRingingMode.CUCAndCompanionDevice">
              {{ 'CALLING_PREFERENCES.COMPANION_RING_CUC_AND_DEVICE' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
