import { Injectable } from '@angular/core';

import { WebPopCallObject } from '../models/integrations.models';
import { LambdaCallResult, LambdaFileModel, LambdaLogDBModel, LogObject, LogType } from '../models/lambda.model';
import { LambdaStorageService } from './lambda-storage.service';

@Injectable({
  providedIn: 'root',
})
export class LambdaService {
  private readonly LOG_FILE_PREFIX = 'lambda-execution-';
  private readonly LOG_FILE_EXTENSION = '.log';

  public logs$ = this.lambdaStorageService.liveQuery();

  constructor(private lambdaStorageService: LambdaStorageService) {}

  public async callLambdaFunction(
    integrationTitle: string,
    lambda: string,
    callObj: WebPopCallObject
  ): Promise<LambdaCallResult> {
    const lambdaOutputLogs: LogObject[] = [];

    // TODO: refactor
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (globalThis as any).customLog = function (logType: LogType, ...args: any[]): string {
      let result = '';

      for (const argument of args) {
        if (typeof argument === 'string') {
          result += `${argument}`;
        } else {
          try {
            result += `${JSON.stringify(argument)}`;
          } catch {
            result += `${argument.toString()}`;
          }
        }
      }

      lambdaOutputLogs.push({ logType, output: result });

      return result;
    };

    try {
      lambda = this.replaceConsoleCalls(lambda);
      const lambdaResult = await this.runLambda(lambda, callObj);
      await this.logLambdaCall({
        integrationTitle,
        callObj,
        lambdaOutputLogs,
        errorMessage: undefined,
        lambdaResult,
        createdAt: Date.now().toString(),
      });
      return {
        errorMessage: undefined,
        lambdaResult,
        logs: lambdaOutputLogs,
      };
    } catch (error) {
      const errorMessage = this.getLambdaCallErrorMessage(error);
      await this.logLambdaCall({
        integrationTitle,
        callObj,
        lambdaOutputLogs,
        errorMessage,
        lambdaResult: undefined,
        createdAt: Date.now().toString(),
      });
      return {
        errorMessage,
        lambdaResult: undefined,
        logs: lambdaOutputLogs,
      };
    } finally {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (globalThis as any).customLog = undefined;
    }
  }

  private async runLambda(lambda: string, callObj: WebPopCallObject): Promise<string> {
    const fn = (0, eval)(`(${lambda})`);
    return await fn(callObj);
  }

  private getLambdaCallErrorMessage(error: unknown) {
    console.error('Error calling lambda function:', error);

    let err: string;
    if (error instanceof SyntaxError) {
      err = error.message;
    } else if (error instanceof Error) {
      err = error.message;
    } else {
      err = JSON.stringify(error);
    }
    return err;
  }

  downloadLogFile(model: LambdaLogDBModel) {
    const _fileModel: LambdaFileModel = {
      fileName: this.getLogFileName(model.createdAt),
      content: model,
    };
    // window.electron.saveFile(fileModel);
  }

  private async logLambdaCall(model: LambdaLogDBModel) {
    await this.lambdaStorageService.insert(model);
  }

  private replaceConsoleCalls(lambda: string): string {
    const methods = ['log', 'info', 'warn', 'error'];

    methods.forEach((method) => {
      const regex = new RegExp(`console.${method}\\(`, 'g');
      lambda = lambda.replace(regex, `customLog('${method}',`);
    });

    return lambda;
  }

  private getLogFileName(date: string): string {
    return this.LOG_FILE_PREFIX + date + this.LOG_FILE_EXTENSION;
  }
}
