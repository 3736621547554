import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CompanionRingingMode, DefaultCompanionSettings } from '@app/phone/models/companion.models';
import { CompanionService } from '@app/phone/services/companion.service';
import { SettingsPage } from '@app/preferences/models/settings-page';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize, firstValueFrom } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-calling',
  templateUrl: './calling.component.html',
  styleUrls: ['./calling.component.scss'],
})
export class CallingComponent extends SettingsPage {
  protected readonly CompanionRingingMode = CompanionRingingMode;

  protected companionForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private companionService: CompanionService
  ) {
    super();

    this.companionForm = this.fb.group({
      rememberState: DefaultCompanionSettings.remember,
      ringingMode: DefaultCompanionSettings.ringing,
    });

    this.companionService.companionSettings$.pipe(untilDestroyed(this)).subscribe((settings) => {
      this.companionForm.patchValue({ rememberState: settings.remember, ringingMode: settings.ringing });
    });

    this.companionForm.statusChanges.subscribe(() => {
      this.disabled = this.companionForm.pristine;
    });
  }

  public async save() {
    if (this.companionForm.dirty) {
      this.loading = true;

      const preferences = this.companionForm.value;

      await firstValueFrom(
        this.companionService.storePreferences(preferences).pipe(finalize(() => (this.loading = false)))
      );
    }
  }
}
