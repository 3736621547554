<div
  *ngIf="open"
  @expandCollapse
  [class]="'container banner ' + type">
  <div class="status flex align-items-center">
    <button
      *ngIf="closeClick.observers.length !== 0"
      class="close-button"
      (click)="closeClick.emit()"
      [matTooltip]="closeTooltip">
      <mat-icon>close</mat-icon>
    </button>
    <div class="text-container">
      <mat-icon
        *ngIf="icon || customIcon"
        [svgIcon]="customIcon">
        {{ icon }}
      </mat-icon>
      <span
        class="status-text"
        [innerHTML]="text">
      </span>
    </div>
    <div class="buttons-container">
      <ng-template
        ngFor
        let-action
        [ngForOf]="transformedActions">
        <span
          *ngIf="action.tooltip; else withoutTooltip"
          class="action-btn"
          (click)="handleActionClick(action)"
          [matTooltip]="action.tooltip">
          {{ action.text }}
          <mat-icon>chevron_right</mat-icon>
        </span>
        <ng-template #withoutTooltip>
          <span
            class="action-btn"
            (click)="handleActionClick(action)">
            {{ action.text }}
            <mat-icon>chevron_right</mat-icon>
          </span>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
