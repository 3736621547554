import { DIALOG_DATA } from '@angular/cdk/dialog';
import { AfterViewInit, Component, Inject, OnInit, Optional, QueryList, ViewChildren } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Contact } from '@app/contacts/models/contact';
import { ContactService } from '@app/contacts/services/contact.service';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { VoicemailSettingsApiService } from '@app/preferences/services/voicemail-settings-api.service';
import { VoicemailSettingsFacadeService } from '@app/preferences/services/voicemail-settings-facade.service';
import { VoicemailSettingsStateService } from '@app/preferences/services/voicemail-settings-state.service';
import { checkUpdate, updateRejected } from '@app/shared/utils/update.util';
import { environment } from '@environment/environment';
import { delay } from 'rxjs';

import { SettingType } from '../../models/settings.models';
import { SettingsPage } from '../../models/settings-page';

@Component({
  selector: 'app-preference-layout',
  templateUrl: './preference-layout.component.html',
  styleUrls: ['./preference-layout.component.scss'],
  providers: [VoicemailSettingsFacadeService, VoicemailSettingsApiService, VoicemailSettingsStateService],
})
export class PreferenceLayoutComponent implements OnInit, AfterViewInit {
  @ViewChildren('page') pages: QueryList<SettingsPage>;

  protected readonly SettingType = SettingType;
  protected readonly updateRejected = updateRejected;

  protected currentSelect: string = SettingType.Profile;
  protected currentPage?: SettingsPage;
  protected profile?: Contact;
  protected debugVersion?: string;
  protected panelItems: string[];

  constructor(
    private contactService: ContactService,
    private appConfigService: AppConfigService,
    @Optional() @Inject(DIALOG_DATA) public data: SettingType | null,
    private swUpdate: SwUpdate
  ) {
    this.debugVersion = environment.awsCommitId;
  }

  ngOnInit(): void {
    this.contactService.currentUser$.subscribe((contact) => {
      this.profile = contact;
    });

    this.getPanels();
  }

  ngAfterViewInit() {
    // Since currentPage is used with `ngIf` in our template and it starts with a value of `undefined`,
    // delay this observable to the next cycle of the run loop to allow for the view change detection
    // to stabilize and avoid the "Expression has changed after it was checked" error in development mode.
    // See https://blog.angular-university.io/angular-debugging/ for more info.
    this.pages.changes.pipe(delay(0)).subscribe(() => {
      // due to ngIf, each time just one page is visible on the screen, that's why we obtain it via `first`
      this.currentPage = this.pages.first;
    });

    if (this.data) {
      this.currentSelect = this.data;
    }
  }

  private getPanels() {
    this.panelItems = Object.values(SettingType);

    if (!this.appConfigService.features[AppFeature.CompanionMode]) {
      // removing whole Calling panel because now it contains only Companion mode related settings
      // however, this may change in the future
      this.panelItems.splice(this.panelItems.indexOf(SettingType.Calling), 1);
    }
  }

  protected async save(page: SettingsPage) {
    await page.save();
    this.currentSelect = SettingType.Profile;
  }

  protected async onUpdate() {
    if (updateRejected) {
      // the reason why we don't call checkUpdate is swUpdate.checkForUpdate() will return false if the user rejects
      // the update at the first time
      if (confirm('New version available. Load New Version?')) {
        window.location.reload();
      }
    } else {
      await checkUpdate(this.swUpdate);
    }
  }
}
