import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { AppFeature } from '@app/core/models/config.models';
import { AppConfigService } from '@app/core/services/app-config.service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDividerModule, MatButtonModule, MatIconModule],
})
export class AppsComponent {
  protected readonly AppFeature = AppFeature;
  constructor(protected configService: AppConfigService) {}

  downloadWindows() {
    window.open('https://connectuc-releases.s3.us-east-1.amazonaws.com/desktop-prod/latest/ConnectUC.exe', '_blank');
  }

  downloadMacOS() {
    window.open('https://connectuc-releases.s3.us-east-1.amazonaws.com/desktop-prod/latest/ConnectUC.dmg', '_blank');
  }
}
