<div class="apps">
  <div class="scrollable-content">
    <div class="content">
      @if (configService.features[AppFeature.MobileDownloads]) {
        <h1>
          {{ 'APPS.MOBILE_APP_ACCESS' | translate }}
        </h1>
        <mat-divider></mat-divider>
        <span>{{ 'APPS.MOBILE_APP_HELP_TEXT' | translate }}</span>
        <div class="stores">
          <div class="store-layout">
            <img src="../../../../../assets/AppleAppStoreQRcode.png" />
            <img src="../../../../../assets/AppleAppStore.png" />
          </div>
          <div class="store-layout">
            <img src="../../../../../assets/GooglePlayStoreQRcode.png" />
            <img src="../../../../../assets/GooglePlayStore.png" />
          </div>
        </div>
      }
      @if (configService.features[AppFeature.DesktopDownloads]) {
        <h1>{{ 'APPS.DESKTOP_APP_ACCESS' | translate }}</h1>
        <mat-divider></mat-divider>
        <span>{{ 'APPS.DESKTOP_APP_HELP_TEXT' | translate }}</span>
        <button
          color="primary"
          class="download"
          mat-raised-button
          (click)="downloadWindows()">
          <mat-icon>download</mat-icon>{{ 'APPS.DOWNLOAD' | translate }} (Windows)
        </button>
        <button
          color="primary"
          class="download"
          mat-raised-button
          (click)="downloadMacOS()">
          <mat-icon>download</mat-icon>{{ 'APPS.DOWNLOAD' | translate }} (macOS)
        </button>
      }
    </div>
  </div>
</div>
