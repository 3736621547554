import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CallDirection } from '@app/call-history/models/call-history.models';
import { AppFeature } from '@app/core/models/config.models';
import { ApiService } from '@app/core/services/api.service';
import { AppConfigService } from '@app/core/services/app-config.service';
import { WsService } from '@app/core/services/ws.service';
import { ElectronService } from '@app/electron/electron.service';
import { Call } from '@app/phone/models/call.model';
import { CompanionCall } from '@app/phone/models/companion.models';
import normalizePhoneNumber from '@app/shared/utils/phone.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ElectronChannel } from '@shared/types';
import { BehaviorSubject, finalize, firstValueFrom, map, Observable, of, tap } from 'rxjs';

import {
  CallAnsweredEventData,
  CallEventTypes,
  CallRangEventData,
  CallStartedEventData,
} from '../models/call-events.model';
import { Integration, LaunchAutoPopType, WebPopCallObject } from '../models/integrations.models';
import { LambdaService } from './lambda.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class IntegrationsService extends ApiService {
  private integrationsSubject = new BehaviorSubject<Integration[] | undefined>(undefined);
  private isLoadingSubject = new BehaviorSubject<boolean>(false);

  /**
   * The list of available integrations. Will be undefined until the first time the API call
   * has been made. At that point it will be an empty array if no integrations are available.
   */
  public integrations$ = this.integrationsSubject.asObservable();
  public nonAutoWebPopIntegrations$ = this.integrations$.pipe(
    map((integrations) => this.getNonAutoWebPopIntegrations(integrations ?? []))
  );
  public isLoading$ = this.isLoadingSubject.asObservable();

  /**
   * A set of callIds belonging to events that have been processed. This allows us to filter out
   * duplicate WS events due to device differences
   */
  private handledEventCallIds = new Set<string>();

  private get integrations(): Integration[] | undefined {
    return this.integrationsSubject.value;
  }

  constructor(
    httpClient: HttpClient,
    private lambdaService: LambdaService,
    private wsService: WsService,
    private appConfigService: AppConfigService,
    private configService: AppConfigService,
    private electronService: ElectronService
  ) {
    super(httpClient);
  }

  public init() {
    this.setUpWebPopListeners();

    this.configService.features$.pipe(untilDestroyed(this)).subscribe((features) => {
      if (
        this.electronService.isRunningInElectron &&
        features[AppFeature.WebPopIntegration] &&
        this.integrations === undefined
      ) {
        this.refreshData().subscribe();
      }
    });
  }

  private setUpWebPopListeners() {
    const openIncomingCallAutoWebPops = async (
      event: CallAnsweredEventData | CallRangEventData,
      launchAutoPopType: LaunchAutoPopType
    ) => {
      const callObject = this.createWebPopCallObj(
        event.to,
        event.caller_id,
        event.caller_name,
        event.to,
        event.orig_callid,
        CallDirection.incoming,
        event.time_start
      );

      const integrationsToOpen = (this.integrations ?? []).filter(
        (i) => i.launch_scenarios.onIncomingCall.event === launchAutoPopType
      );
      await this.openIncomingCallAutoWebPops(callObject, integrationsToOpen);
    };

    this.wsService.socket.on(CallEventTypes.CallRang, async (event: CallRangEventData) => {
      if (this.handledEventCallIds.has(event.orig_callid)) {
        return;
      }

      this.addEventCallIdToSet(event.orig_callid);

      await openIncomingCallAutoWebPops(event, LaunchAutoPopType.ON_RING);
    });

    this.wsService.socket.on(CallEventTypes.CallAnswered, async (event: CallAnsweredEventData) => {
      if (this.handledEventCallIds.has(event.orig_callid)) {
        return;
      }

      this.addEventCallIdToSet(event.orig_callid);

      // CallAnswered is a domain event and needs to be filtered by aor to get events just for this user
      if (event.aor === this.appConfigService.provision?.data.nsUid) {
        await openIncomingCallAutoWebPops(event, LaunchAutoPopType.ON_ANSWER);
      }
    });

    this.wsService.socket.on(CallEventTypes.CallStarted, async (event: CallStartedEventData) => {
      if (this.handledEventCallIds.has(event.orig_callid)) {
        return;
      }

      this.addEventCallIdToSet(event.orig_callid);
      const callObject = this.createWebPopCallObj(
        event.to,
        event.caller_id,
        event.caller_name,
        event.to,
        event.orig_callid,
        CallDirection.outgoing,
        event.time_start
      );

      await this.openOutgoingCallAutoWebPops(callObject, this.integrations ?? []);
    });
  }

  private addEventCallIdToSet(callId: string) {
    if (this.handledEventCallIds.size > 1000) {
      // Keep the 5 most recent in case we get a few calls in quick succession
      const callIds = Array.from(this.handledEventCallIds);
      this.handledEventCallIds = new Set(callIds.slice(callIds.length - 5));
    }
    this.handledEventCallIds.add(callId);
  }

  // ========== API ==========

  public refreshData(): Observable<Integration[] | void> {
    if (!this.electronService.isRunningInElectron || !this.configService.features[AppFeature.WebPopIntegration]) {
      return of(void 0);
    }

    this.isLoadingSubject.next(true);
    return this.get<Integration[]>(`users/{me}/link-integrations-extensions`).pipe(
      untilDestroyed(this),
      tap((integrations) => {
        this.integrationsSubject.next(integrations);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  public async putIntegration(
    integration: Pick<Integration, 'id' | 'enabled' | 'auto_pop' | 'auto_dial'>
  ): Promise<void> {
    const body = {
      enabled: integration.enabled,
      auto_pop: integration.auto_pop,
      auto_dial: integration.auto_dial,
    };

    return await firstValueFrom(
      this.post<void>(`users/{me}/link-integrations-extensions/${integration.id}`, body, {
        method: 'PUT',
      }).pipe(
        tap(() => {
          const integrations = this.integrations;
          if (integrations) {
            const updatedIntegrations = integrations.map((i) => (i.id === integration.id ? { ...i, ...body } : i));
            this.integrationsSubject.next(updatedIntegrations);
          }
        })
      )
    );
  }

  // ========== Web Pop ==========

  public async openWebPopForIntegration(integration: Integration, call: Call | CompanionCall | WebPopCallObject) {
    let webPopCall: WebPopCallObject;
    if (call instanceof Call) {
      webPopCall = this.createWebPopCallWithSIPCall(call);
    } else if (call instanceof CompanionCall) {
      webPopCall = this.createWebPopCallWithCompanionCall(call);
    } else {
      webPopCall = call;
    }

    const url = await this.getIntegrationUrl(integration, webPopCall);
    if (url) {
      if (this.electronService.isRunningInElectron) {
        this.electronService.send(ElectronChannel.OpenLink, { url });
      } else {
        window.open(url, '_blank');
      }
    }
  }

  private async openOutgoingCallAutoWebPops(callObj: WebPopCallObject, integrations: Integration[]) {
    const outgoingIntegrations = this.getAutoWebPopIntegrations(integrations, CallDirection.outgoing);
    await Promise.all(outgoingIntegrations.map((integration) => this.openWebPopForIntegration(integration, callObj)));
  }

  // Opening Auto web pops for incoming calls
  private async openIncomingCallAutoWebPops(callObj: WebPopCallObject, integrations: Integration[]) {
    const incomingIntegrations = this.getAutoWebPopIntegrations(integrations, CallDirection.incoming);
    await Promise.all(incomingIntegrations.map((integration) => this.openWebPopForIntegration(integration, callObj)));
  }

  private async getIntegrationUrl(integration: Integration, callObject: WebPopCallObject): Promise<string | undefined> {
    // Assign the integration's customer_id value to the call object, so then it gets replaced by the provided value.
    callObject.customer_id = integration.variables.customer_id ?? '';

    let url: string | undefined;
    if (integration.lambda) {
      const result = await this.lambdaService.callLambdaFunction(
        integration.display_name,
        integration.lambda,
        callObject
      );
      url = result.lambdaResult;
    } else {
      url = this.createIntegrationUrl(integration, callObject);
    }
    return url;
  }

  private createIntegrationUrl(integration: Integration, callObject: WebPopCallObject): string {
    try {
      let integrationUrl = integration.launch_url;
      const integrationParams = {
        $CALLER_ID_10_DIGIT: this.getProcessedNationalNumber(callObject.phone_number),
        $PHONE_NUMBER: callObject.phone_number,
        $CALLER_ID: callObject.caller_ID,
        $CALL_ID: callObject.call_ID,
        $DATE_TIME: callObject.date_time ?? new Date().toISOString(),
        $DIALED_NUMBER_10_DIGIT: callObject.dialed_number
          ? this.getProcessedNationalNumber(callObject.dialed_number)
          : '',
        $DIALED_NUMBER: callObject.dialed_number ?? '',
        $CALL_DIRECTION: callObject.call_direction === 'incoming' ? 'incoming' : 'outgoing',
        $CUSTOMER_ID: callObject.customer_id ?? '',
      };

      for (const [key, value] of Object.entries(integrationParams)) {
        integrationUrl = integrationUrl.replaceAll(key, value);
      }

      return integrationUrl;
    } catch (error) {
      console.error('Error calling lambda function:', error);
      return '';
    }
  }

  private createWebPopCallWithSIPCall(call: Call): WebPopCallObject {
    if (call.direction === 'outgoing') {
      console.warn('Cannot create web pop call object for outgoing call');
    }

    // Strip the suffix character off the "to" field.
    const to = call.session.remoteIdentity.uri.user?.replace(/[a-zA-Z]+$/, '') || '';
    return this.createWebPopCallObj(
      to,
      call.remoteUriUser,
      call.remoteDisplayName || call.remoteUriUser,
      call.remoteUriUser,
      call.origCallId || call.id,
      call.direction === 'incoming' ? CallDirection.incoming : CallDirection.outgoing,
      call.createdTimestamp.toISOString()
    );
  }

  private createWebPopCallWithCompanionCall(call: CompanionCall): WebPopCallObject {
    if (call.direction === 'outgoing') {
      console.warn('Cannot create web pop call object for outgoing call');
    }

    return this.createWebPopCallObj(
      call.remoteUriUser,
      call.remoteUriUser,
      call.remoteDisplayName || call.remoteUriUser,
      call.remoteUriUser,
      call.id,
      call.direction === 'incoming' ? CallDirection.incoming : CallDirection.outgoing,
      call.createdTimestamp.toISOString()
    );
  }

  private createWebPopCallObj(
    phoneNumber: string,
    callerId: string,
    callerName: string,
    dialedNumber: string | null,
    callId: string,
    callDirection: CallDirection,
    dateTime?: string | null
  ): WebPopCallObject {
    return {
      phone_number: phoneNumber,
      caller_ID_10_digit: this.getProcessedNationalNumber(callerId),
      caller_ID: callerId,
      caller_name: callerName ?? '',
      call_direction: callDirection,
      date_time: dateTime ?? new Date().toISOString(),
      call_ID: callId,
      dialed_number: dialedNumber ?? '',
      dialed_number_10_digit: dialedNumber ? this.getProcessedNationalNumber(dialedNumber) : '',
    };
  }

  // ========== Helpers ==========

  private getAutoWebPopIntegrations(integrations: Integration[], direction: CallDirection): Integration[] {
    return integrations.filter((integration) => {
      const enabled = integration.enabled;
      const autoPopsInDirection = direction === CallDirection.incoming ? integration.auto_pop : integration.auto_dial;
      return enabled && autoPopsInDirection;
    });
  }

  private getNonAutoWebPopIntegrations(integrations: Integration[]): Integration[] {
    return integrations.filter(
      (integration) => integration.enabled && (!integration.auto_pop || !integration.auto_dial)
    );
  }

  private getProcessedNationalNumber(tel: string): string {
    const result = this.getNationalNumberOrDefault(tel);
    return this.getLastCharacters(result, 10);
  }

  private getLastCharacters(str: string, charCount: number): string {
    return str.trim().slice(-charCount);
  }

  public getNationalNumberOrDefault(tel: string): string {
    try {
      const number = normalizePhoneNumber(tel);
      return number;
    } catch {
      return tel;
    }
  }
}
